import React from "react";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import logo  from '../assets/mngm-logo.png';

export default function MainLayOut({ children }: { children: ReactNode }) {
  return (
    <div>
      <header className="text-gray-700 body-font border-b border-gray-200 mngm-bg">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <Link
            to="/home"
            className="flex title-font font-medium items-center mngm-text mb-4 md:mb-0"
          >
            Home
          </Link>
          <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
            <a
              target="_blank"
              href="https://mngm.com/"
              className="mr-5 hover:text-gray-900"
            >
            <img src={logo} width="74" alt="mngm logo"></img>
            </a>
        
          </nav>
        </div>
      </header>

      <main className="container mx-auto m-4 mt-10">{children}</main>

      <footer className="p-5 mngm-bg text-center text-white lg:text-left">
        <div className="flex flex-col md:flex-row justify-between items-center px-5 md:px-10">
          <div className="pb-4 text-sm text-light opacity-60 md:mr-4 md:pb-0 flex items-center">
            <b className="text-light m-1">&copy; Mngm</b> 2023 All rights
            reserved.
          </div>

          <div className="pb-4 text-sm text-light opacity-60 md:pb-0 flex items-center">
            <b className="text-light">Developed by</b>
            <a
              className="m-1 text-white"
              href="http://inthekloud.com"
              aria-label="inthekloud"
              target="_blank"
            >
              <strong className="underline">InTheKloud</strong>
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}
