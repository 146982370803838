import { useState, useContext, useEffect } from "react";
import { QueryService } from "../../services/QueryService";
import { moneyFormat } from "../../helpers/functions";
import { toast } from "react-toastify";
import {
  ModalContext,
  RejectPledgingRequestModalContext,
} from "../../state/state-provider";

interface CollateralizationResult {
  firstName: string;
  middleName: string;
  lastName: string;
  phoneNumber: string;
  idNumber: string | null | number;
  collateralizationValue: string;
}

const Query = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isNoResult, setIsNoResult] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<
    CollateralizationResult | any
  >(null);
  const queryService = new QueryService();
  const { toggleModal, updateQueryModalData, queryModalData } =
    useContext(ModalContext);

  const { updateRejectRequestModalData, rejectRequestModalData } = useContext(
    RejectPledgingRequestModalContext
  );

  const { toggleRejectModal } = useContext(RejectPledgingRequestModalContext);

  useEffect(() => {
    if (queryModalData == null) {
      setSearchTerm("");
      setIsNoResult(false);
      setSearchResults(null);
    }
  }, [queryModalData]);

  const handleSearch = async () => {
    setSearchResults(null);

    if (searchTerm.length !== 10) {
      toast.error("Lock reference code should be 10 characters", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      return;
    }

    const response = await queryService.show(searchTerm);

    if (response?.error) {
      toast.error(response?.error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setIsNoResult(true);
      return;
    }

    if (typeof response?.data === "undefined") {
      setIsNoResult(true);
      return;
    }

    setSearchResults(response?.data);
    setIsNoResult(false);
  };

  const holdClientStorage = async () => {
    toggleModal(true);
    updateQueryModalData({
      lockReferenceCode: searchResults.lockReferenceCode,
      requestId: searchResults.requestId,
    });
  };

  const rejectPledgingRequest = async () => {
    toggleRejectModal(true);
    updateRejectRequestModalData({
      ...rejectRequestModalData,
      requestId: searchResults.requestId,
    });
  };

  return (
    <div className="m-5 p-5">
      <label
        htmlFor="default-search"
        className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
      >
        Search
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            className="w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
        </div>
        <input
          type="text"
          id="default-search"
          className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white focus:outline-none"
          placeholder="lock Reference Id"
          value={searchTerm}
          minLength={10}
          maxLength={10}
          onChange={(e) => {
            const regex = /^\d*$/;

            if (regex.test(e.target.value)) {
              setSearchTerm(e.target.value);
              setIsNoResult(false);
            }
          }}
          required
        />
        <button
          type="button"
          disabled={searchTerm.length !== 10}
          onClick={handleSearch}
          className="disabled:cursor-not-allowed disabled:bg-slate-500 text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Search
        </button>
      </div>
      <div className="mt-5 flex flex-col items-center lg:flex-row lg:justify-center">
        {searchResults !== null && (
          <div className="mt-5 rounded-lg overflow-hidden cardHoverEffect ease-in-out w-full lg:w-1/2">
            <div className="px-4 py-2">
              <div className="flex flex-wrap mb-2">
                <div className="w-full p-1 flex flex-wrap justify-center md:justify-start">
                  <span className="bg-yellow-500 text-white rounded-lg px-2 py-1 text-xs md:text-sm lg:text-sm font-bold my-1 mx-2">
                    Name
                  </span>
                  <p className="text-gray-800 font-bold"></p>
                  <span className="font-bold my-2 text-sm sm:text-base">
                    {searchResults?.firstName ?? ""}{" "}
                    {searchResults?.middleName ?? ""}{" "}
                    {searchResults?.lastName ?? ""}
                  </span>
                </div>

                {searchResults?.phoneNumber && (
                  <div className="w-full p-1 flex flex-wrap justify-center md:justify-start">
                    <span className="bg-yellow-500 text-white rounded-lg px-2 py-1 text-xs md:text-sm lg:text-sm font-bold  my-1 mx-2">
                      Phone Number
                    </span>
                    <span className="font-bold my-2 text-sm sm:text-base">
                      {searchResults?.phoneNumber}
                    </span>
                  </div>
                )}

                {searchResults?.email && (
                  <div className="w-full p-1 flex flex-wrap justify-center md:justify-start">
                    <span className="bg-yellow-500 text-white rounded-lg px-2 py-1 text-xs md:text-sm lg:text-sm font-bold  my-1 mx-2">
                      Email
                    </span>
                    <span className="font-bold my-2 text-sm sm:text-base">
                      {searchResults?.email}
                    </span>
                  </div>
                )}

                <div className="w-full p-1 flex flex-wrap justify-center md:justify-start">
                  <span className="bg-yellow-500 text-white rounded-lg px-2 py-1 text-xs md:text-sm font-bold lg:font-bold my-1 mx-2">
                    Pledging Value
                  </span>
                  <span className="font-bold my-2 text-sm sm:text-base">
                    {moneyFormat(searchResults?.collateralizationValue)}
                  </span>
                </div>
                <div className="w-full p-1 flex flex-wrap justify-center md:justify-start ">
                  <span className="bg-yellow-500 text-white rounded-lg px-2 py-1 text-xs md:text-sm lg:text-sm font-bold  my-1 mx-2">
                    ID Number
                  </span>
                  <span className="font-bold my-2 text-sm sm:text-base">
                    {searchResults?.idNumber}
                  </span>
                </div>
                <div className="w-full p-1 flex flex-wrap justify-center md:justify-start ">
                  <span className="bg-yellow-500 text-white rounded-lg px-2 py-1 text-xs md:text-sm lg:text-sm font-bold  my-1 mx-2">
                    Total Quantity In Gram
                  </span>
                  <span className="font-bold my-2 text-sm sm:text-base">
                    {searchResults?.totalQuantityInGram}
                  </span>
                </div>

                <div className="w-full p-1 flex flex-wrap justify-center md:justify-end space-x-2">
                  <span className="font-bold my-2 text-sm sm:text-base">
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                      onClick={rejectPledgingRequest}
                    >
                      Reject
                    </button>
                  </span>
                  <span className="font-bold my-2 text-sm sm:text-base">
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                      onClick={holdClientStorage}
                    >
                      Hold Client Storage
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {isNoResult && (
          <div className="bg-white rounded-lg shadow p-4 my-2 w-full lg:w-1/2">
            <span className="font-semibold text-red-600 text-sm sm:text-base">
              {searchTerm} Not Found ...
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Query;
