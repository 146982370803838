import React, { useContext } from "react";
import { ModalContext } from "../state/state-provider";
import { QueryService } from "../services/QueryService";
import { toast } from "react-toastify";

const HoldClientStorageModal: React.FC = () => {
  const { isOpen, queryModalData, toggleModal , resetQueryData } = useContext(ModalContext);
  const queryService = new QueryService();


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const response = await queryService.holdClientStorage({
      isReceived: true,
      requestId: queryModalData?.requestId ?? null,
    });

    if (response?.error) {
      toast.error(response?.error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }


    if (response.status === 200) {
      toast.success('Hold Completed Successfully', {
        position: "top-center",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        onClose: resetQueryData,
      });
      toggleModal(false);
      return;
    }


  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <div
            className="bg-white rounded-lg shadow-lg overflow-hidden transform transition-all max-w-lg w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <form onSubmit={handleSubmit}>
                <div className="w-full p-1 flex flex-wrap justify-center md:justify-center lg:mb-5">
                <p className="font-bold my-2 text-sm sm:text-base">Are you sure you want to pledge this request ?</p>

                </div>

                <div className="w-full p-1 flex flex-wrap justify-center md:justify-start ">
                  <span className="bg-yellow-500 text-white rounded-lg px-2 py-1 text-xs md:text-sm lg:text-sm font-bold  my-1 mx-2">
                    Lock Reference Code
                  </span>
                  <span className="font-bold my-2 text-sm sm:text-base">
                    {queryModalData?.lockReferenceCode ?? ""}
                  </span>
                </div>

                <div className="flex justify-end">
                  <button
                    type="button"
                    className="bg-gray-200 text-gray-700 font-bold py-2 px-4 rounded mr-2"
                    onClick={() => toggleModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Hold
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HoldClientStorageModal;
