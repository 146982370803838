import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import ProtectedRoute from "./middleware/ProtectedRoute";
import Login from "./Pages/Login/Login";
import Query from "./Pages/Query/Query";
import HoldClientStorageModal from "./modals/HoldClientStorageModal";
import Home from "./Pages/Home/Home";
import Requests from "./Pages/Requests/Requests";
import RejectPledgingRequest from "./modals/RejectPledgingRequest/RejectPledgingRequest";
import MainLayOut from "./layouts/MainLayOut";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <MainLayOut>
              <Login />
            </MainLayOut>
          }
        />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/requests"
          element={
            <ProtectedRoute>
              <Requests />
            </ProtectedRoute>
          }
        />
        <Route
          path="/query"
          element={
            <ProtectedRoute>
              <Query />
            </ProtectedRoute>
          }
        />
      </Routes>
      <HoldClientStorageModal />
      <RejectPledgingRequest />
    </BrowserRouter>
  );
}

export default App;
