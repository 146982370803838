import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import MainLayOut from "./layouts/MainLayOut";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { StateProvider } from "./state/state-provider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
    <StateProvider>
      <App />
      <ToastContainer />
    </StateProvider>
);
