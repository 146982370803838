import { DefaultTostSettingObject } from "../types/defaultTostSettingObject";

export const defaultTostSettingObject:DefaultTostSettingObject|unknown  = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  }