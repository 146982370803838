import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useState } from "react";
import { convertStringToArray } from "../helpers/functions";

export default function PageSizeBox({
  pageSizes,
  currentPageSize,
  onChange,
  className

}: {
  pageSizes: string;
  currentPageSize: string;
  onChange:Function
  className?:string
}) {
  const [_currentPageSize, setCurrentPageSize] = useState<string | number>(
    currentPageSize
  );
  
  const handleChange = (event: SelectChangeEvent) => {
    setCurrentPageSize(event.target.value as string);
    onChange({
        PAGESIZE:event.target.value
      });
  };

  
  const _pageSizes = convertStringToArray(pageSizes);

  return (
    <Box className={className}  sx={{ minWidth: 60 }}>
      <FormControl fullWidth>
        {/* <InputLabel id="demo-simple-select-label">Page Size</InputLabel> */}
        <Select
          // labelId="demo-simple-select-label"
          // id="demo-simple-select"
          value={String(_currentPageSize)}
          // label="Page Size"
          onChange={handleChange}
        >
          {_pageSizes.map((s) => (
            <MenuItem key={s} value={s}>{s}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
