import {
  createContext,
  useState,
  ReactNode,
  FC,
  FunctionComponent,
} from "react";
import { RejectRequestModalDataIF } from "../types/RejectRequestModalDataIF";

interface User {
  user: string;
  isLoggedIn: boolean;
}

interface AuthContextValue {
  user: User | null;
  login: (user: string, isLoggedIn: boolean) => void;
  logout: (user: string, isLoggedIn: boolean) => void;
}

interface QueryModalInterface {
  isOpen: boolean;
  toggleModal: (state: boolean) => void;
  resetQueryData: () => void;
  updateQueryModalData: (data: {
    lockReferenceCode: number | null;
    requestId: number | null;
  }) => void;
  queryModalData: {
    lockReferenceCode: number | null;
    requestId: number | null;
  } | null;
}

interface QueryRejectModalInterface {
  isOpenRejectModal: boolean;
  toggleRejectModal: (state: boolean) => void;
  updateRejectRequestModalData: (data: RejectRequestModalDataIF) => void;
  rejectRequestModalData: RejectRequestModalDataIF| null;
}

const AuthContext = createContext<AuthContextValue>({
  user: null,
  login: () => {},
  logout: () => {},
});

const ModalContext = createContext<QueryModalInterface>({
  isOpen: false,
  toggleModal: () => {},
  updateQueryModalData: () => {},
  resetQueryData: () => {},
  queryModalData: null,
});

const RejectPledgingRequestModalContext =
  createContext<QueryRejectModalInterface>({
    isOpenRejectModal: false,
    toggleRejectModal: () => {},
    updateRejectRequestModalData: () => {},
    rejectRequestModalData: null,
  });

const StateProvider: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [isOpenRejectModal, setIsOpenRejectModal] = useState<boolean>(false);

  const [queryModalData, setQueryModalData] = useState<{
    lockReferenceCode: number | null;
    requestId: number | null;
  } | null>({
    lockReferenceCode: 0,
    requestId: 0,
  });

  const [rejectRequestModalData, setRejectRequestModalData] = useState<{
    rejectionReason?: string | null;
    requestId?: number | null;
    note?: string | null;
  } | null>({
    rejectionReason: "",
    requestId: 0,
    note: "",
  });

  const updateRejectRequestModalData = (data: {
    rejectionReason?: string | null;
    requestId?: number | null;
    note?: string | null;
  }) => setRejectRequestModalData(data);

  const updateQueryModalData = (data: {
    lockReferenceCode: number | null;
    requestId: number | null;
  }) => setQueryModalData(data);

  const toggleModal = (state: boolean) => setIsOpen(state);
  const toggleRejectModal = (state: boolean) => setIsOpenRejectModal(state);

  const login = (user: string, isLoggedIn: boolean) => {
    setUser({ user, isLoggedIn });
  };

  const logout = (user: string, isLoggedIn: boolean) => {
    setUser({ user, isLoggedIn });
  };

  const resetQueryData = () => {
    setQueryModalData(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      <ModalContext.Provider
        value={{
          toggleModal,
          updateQueryModalData,
          isOpen,
          queryModalData,
          resetQueryData,
        }}
      >
        <RejectPledgingRequestModalContext.Provider
          value={{
            toggleRejectModal,
            updateRejectRequestModalData,
            isOpenRejectModal,
            rejectRequestModalData,
          }}
        >
          {children}
        </RejectPledgingRequestModalContext.Provider>
      </ModalContext.Provider>
    </AuthContext.Provider>
  );
};

export {
  AuthContext,
  StateProvider,
  ModalContext,
  RejectPledgingRequestModalContext,
};
