const REACT_APP_LOGIN_PASSWORD = process.env.REACT_APP_LOGIN_PASSWORD;
const REACT_APP_LOGIN_EMAIL = process.env.REACT_APP_LOGIN_EMAIL;

interface lgoinResponseValues {
  status:boolean,
  message: string 
}

export class LoginService {
  lgoin(data: { email: string; password: string }) : lgoinResponseValues {
    if (data.email == REACT_APP_LOGIN_EMAIL && data.password == REACT_APP_LOGIN_PASSWORD) {

      return {
        status:true,
        message: 'login Successfully' 
      }
    }

    return {
      status:false,
      message: 'login Invalid' 
    }
  }
}
