import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../state/state-provider";
import MainLayOut from "../layouts/MainLayOut";

const ProtectedRoute = ({ children }: { children: any }) => {
  const { user } = useContext(AuthContext);

  let location = useLocation();
  return <MainLayOut>
    {!user?.isLoggedIn ? (
      <Navigate to="/" state={{ from: location }} replace />
    ) : (
      children
    )}
  </MainLayOut>;
};

export default ProtectedRoute;
