import { useState, useEffect, useContext } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { toast } from "react-toastify";
import { QueryService } from "../../services/QueryService";
import { RejectPledgingRequestReasonsIF } from "../../types/rejectPledgingRequestReasonsIF";
import { RejectPledgingRequestModalContext } from "../../state/state-provider";
const queryService = new QueryService();

export default function ReasonsDropDown() {
  const [reasons, setReasons] = useState<
    RejectPledgingRequestReasonsIF[] | null
  >([]);
  const { updateRejectRequestModalData, rejectRequestModalData } = useContext(
    RejectPledgingRequestModalContext
  );

  const fetchData = async () => {
    try {
      const response = await queryService.getRejectReasons();

      if (response) setReasons(response);
      else throw new Error(response.response.data.error ?? "");
    } catch (error: any) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container ">
      <h1 className="p-1 m-3 text-left font-semibold">Select reason:</h1>
      <div className="flex space-x-4 mb-12  p-4 cardHoverEffect">
        <FormControl variant="outlined" sx={{ minWidth: "100%" }}>
          <InputLabel id="reason-label">Reasons</InputLabel>

          <Select
            labelId="reason-label"
            value={rejectRequestModalData?.rejectionReason}
            onChange={(e) => {
              updateRejectRequestModalData({
                ...rejectRequestModalData,
                rejectionReason: e.target.value,
              });
            }}
            label="Reasons"
          >
            {reasons !== null &&
              reasons.map((item: RejectPledgingRequestReasonsIF) => (
                <MenuItem key={item?.key} value={item?.key}>
                  {item?.nameEn}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
