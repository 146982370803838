import React, { useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SearchIcon from "@mui/icons-material/Search";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Pagination,
  LinearProgress,
} from "@mui/material";
import PageSizeBox from "../../components/PageSizeBox";
import { defaultTostSettingObject } from "../../helpers/objects";
import { toast } from "react-toastify";
import { QueryService } from "../../services/QueryService";
import { RequestIF } from "../../types/requestSearchIF";
import { requestSearchParamsIF } from "../../types/requestSearchParamsIF";
import { MetaData } from "../../types/metadata";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { dateFormat } from "../../helpers/functions";

export default function Requests() {
  const queryService = new QueryService();
  const [loading, setLoading] = useState(false);
  const [searchBy, setSearchBy] = useState("0");
  const [searchValue, setSearchValue] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [metaData, setMetaData] = useState<MetaData>({
    currentPage: 0,
    pageSize: "3",
    totalPages: 0,
  });
  const [data, setData] = useState<RequestIF[]>();

  const inputsValidation = () => {
    if (defaultTostSettingObject) {
      if (!searchBy) {
        toast.error("Please select search by", defaultTostSettingObject);
        return false;
      }
      // if (!searchValue) {
      //   toast.error("Please select search value", defaultTostSettingObject);
      //   return false;
      // }
      if (!fromDate) {
        toast.error("Please select from date", defaultTostSettingObject);
        return false;
      }
      if (!toDate) {
        toast.error("Please select to date", defaultTostSettingObject);
        return false;
      }

      return true;
    }
  };

  const search = async (_params?: requestSearchParamsIF) => {
    const isInputsValid = inputsValidation();
    if (!isInputsValid) return;

    try {
      setLoading(true);
      setData([]);

      const response = await queryService.search({
        searchBy: _params?.searchBy || Number(searchBy),
        searchValue: _params?.searchValue || searchValue,
        toDate: _params?.toDate || toDate,
        fromDate: _params?.fromDate || fromDate,
        export: false,
        PAGENUMBER: _params?.PAGENUMBER || 1,
        PAGESIZE: _params?.PAGESIZE || metaData?.pageSize,
      });

      if (response?.data) {
        setData(response.data);
        setMetaData(response.metadata);
      } else {
        throw new Error(response.response.data.error ?? "");
      }
    } catch (error: any) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  };

  async function exportRequests() {
    try {
      const response = await queryService.exportFile(
        {
          searchBy: Number(searchBy),
          searchValue: searchValue,
          toDate: toDate,
          fromDate: fromDate,
          export: true,
          PAGENUMBER: 1,
          PAGESIZE: metaData?.pageSize,
        },
        "Pledging Requests"
      );
    } catch (error: any) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="p-1 m-3 text-2xl text-left font-semibold uppercase">
        Requests:
      </h1>
      <div className="flex space-x-4 mb-12  p-4 cardHoverEffect">
        <FormControl variant="outlined" sx={{ minWidth: 120 }}>
          <InputLabel id="search-by-label">Search By</InputLabel>
          <Select
            labelId="search-by-label"
            value={searchBy}
            onChange={(e) => setSearchBy(e.target.value)}
            label="Search By"
          >
            <MenuItem value="0">Any</MenuItem>
            <MenuItem value="1">Client ID</MenuItem>
            <MenuItem value="2">Request ID</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Search Value"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          variant="outlined"
          sx={{ minWidth: 200 }}
        />
        <TextField
          type="date"
          label="From"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          sx={{ minWidth: 200 }}
        />
        <TextField
          type="date"
          label="To"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          sx={{ minWidth: 200 }}
        />
        <Button
          disabled={!data?.length}
          onClick={exportRequests}
          className="!ml-auto"
          variant="contained"
          color="info"
        >
          <FileDownloadIcon />
        </Button>
        <Button onClick={() => search()} variant="contained" color="info">
          <SearchIcon />
        </Button>
      </div>

      {loading ? (
        <LinearProgress />
      ) : data?.length ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead className="bg-blue-100 font-semibold">
              <TableRow>
                <TableCell>Client ID</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Lock Reference Code</TableCell>
                <TableCell>Request ID</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Request Status</TableCell>
                <TableCell>Request By</TableCell>
                <TableCell>Request For</TableCell>
                <TableCell>Total Quantity (g)</TableCell>
                <TableCell>Collateral Fee</TableCell>
                <TableCell>Request Value</TableCell>
                <TableCell>Client Name</TableCell>
                <TableCell>Collateral Limit Received</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item: RequestIF, index: number) => (
                <TableRow
                  key={item.requestId}
                  sx={{ backgroundColor: index % 2 === 0 ? "#f9fafb" : "" }}
                >
                  <TableCell>{item.clientId}</TableCell>
                  <TableCell>{dateFormat(item?.createdAt)}</TableCell>
                  <TableCell>{item.lockReferenceCode}</TableCell>
                  <TableCell>{item.requestId}</TableCell>
                  <TableCell>{item.type}</TableCell>
                  <TableCell>{item.requestStatus}</TableCell>
                  <TableCell>{item.requestBy}</TableCell>
                  <TableCell>{item.requestFor}</TableCell>
                  <TableCell>{item.totalQuantityInGram}</TableCell>
                  <TableCell>{item.collateralFee}</TableCell>
                  <TableCell>{item.requestValue}</TableCell>
                  <TableCell>{`${item.clientFirstName} ${item.clientLastName}`}</TableCell>
                  <TableCell>
                    {item.isCollateralLimitReceived ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <HighlightOffIcon color="error" />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <h2 className="p-2 bg-blue-100 rounded-lg shadow-lg text-lg  font-semibold">
          There is no requests to show
        </h2>
      )}
      <div className="flex justify-between items-center mt-5">
        <Pagination
          className="p-2 mr-2 w-full bg-blue-100 text-blue-300 rounded-lg shadow-md "
          count={metaData?.totalPages}
          page={metaData?.currentPage}
          onChange={(e, goTo) => {
            search({
              PAGENUMBER: goTo,
              PAGESIZE: metaData?.pageSize,
            });
          }}
        />
        <PageSizeBox
          className="rounded-lg text-red-500 bg-blue-100"
          currentPageSize={String(metaData?.pagesize ?? 3)}
          pageSizes={String(process.env.REACT_APP_PAGE_SIZES)}
          onChange={search}
        />
      </div>
    </div>
  );
}
