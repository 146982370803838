import axios, { AxiosError } from "axios";
import { requestSearchParamsIF } from "../types/requestSearchParamsIF";
import { RejectRequestModalDataIF } from "../types/RejectRequestModalDataIF";
import { CollateralizationResult } from "../types/CollateralizationResultIF";
import { HoldClientStorageRequest } from "../types/holdClientStorageRequestIF";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const API_KEY_PASSWORD = process.env.REACT_APP_API_KEY_PASSWORD;

export class QueryService {
  async show(lockReferenceId: string): Promise<
    | {
        collateralizationResult: CollateralizationResult[];
      }
    | any
  > {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/valu/collateralization/${lockReferenceId}`,
        {
          auth: {
            username: `${API_KEY}`,
            password: `${API_KEY_PASSWORD}`,
          },
          headers: {
            "X-Requested-With": "XMLHttpRequest",
          },
        }
      );

      if (response.status === 200) {
        return response;
      } else {
        throw new Error(response.data.error);
      }
    } catch (e: any) {
      return e.response?.data as AxiosError;
    }
  }

  async holdClientStorage(data: HoldClientStorageRequest) {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/valu/collateralization/limit`,
        data,
        {
          auth: {
            username: `${API_KEY}`,
            password: `${API_KEY_PASSWORD}`,
          },
        }
      );

      if (response.status === 200) {
        return response;
      } else {
        throw new Error(response.data.error);
      }
    } catch (e: any) {
      return e.response?.data;
    }
  }

  async delete(data: RejectRequestModalDataIF) {
    console.log(data, "data data data");

    try {
      const response = await axios.delete(
        `${API_BASE_URL}/valu/collateralization/reject`,
        {
          method: "delete",
          data: data,
          auth: {
            username: `${API_KEY}`,
            password: `${API_KEY_PASSWORD}`,
          },
        }
      );

      if (response.status === 200) {
        return response;
      } else {
        throw new Error(response.data.error);
      }
    } catch (e: any) {
      return e.response?.data;
    }
  }

  async getRejectReasons() {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/valu/collateralization/request/reject/reason`,
        {
          auth: {
            username: `${API_KEY}`,
            password: `${API_KEY_PASSWORD}`,
          },
        }
      );

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data.error);
      }
    } catch (e: any) {
      return e.response?.data;
    }
  }

  async search(params?: requestSearchParamsIF): Promise<any | AxiosError<any>> {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/valu/collateralization/search`,
        params,
        {
          auth: {
            username: `${API_KEY}`,
            password: `${API_KEY_PASSWORD}`,
          },
          headers: {
            "X-Requested-With": "XMLHttpRequest",
          },
        }
      );

      if (response.status === 200) {
        return response.data;
      } else {
        throw response.data.error;
      }
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        return e;
      }
      throw e;
    }
  }

  async exportFile(
    params: requestSearchParamsIF,
    fileNameWithoutExtension: string
  ) {
    try {
      const response = await axios({
        method: "post",
        url: `${API_BASE_URL}/valu/collateralization/search`,
        data: params,
        auth: {
          username: `${API_KEY}`,
          password: `${API_KEY_PASSWORD}`,
        },
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
        },
        responseType: "blob",
      });

      const blob = new Blob([response.data], {
        type: "application/octet-stream",
      });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      if (typeof fileNameWithoutExtension !== "undefined") {
        a.download = fileNameWithoutExtension + ".xlsx";
      } else {
        a.download = "file.xlsx";
      }

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Export failed:", error);
    }
  }
}
