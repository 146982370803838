import React, { FunctionComponent, useContext } from "react";
import FormLayOut from "../../layouts/FormLayOut";
import useInput from "../../hooks/use-input";
import CustomInput from "../../layouts/CustomInput";
import { useNavigate } from "react-router-dom";
import { LoginService } from "../../services/LoginService";
import { toast } from "react-toastify";
import { AuthContext } from "../../state/state-provider";

const Login: FunctionComponent = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const loginService = new LoginService();

  const {
    value: emailValue,
    isValid: emailValueIsValid,
    hasError: emailValueHasError,
    valueChangeHandler: emailValueChangeHandler,
    inputBlurHandler: emailValueBlurHandler,
    reset: resetemailValue,
  } = useInput((value: string) => true, "");

  const {
    value: passwordValue,
    isValid: passwordValueIsValid,
    hasError: passwordValueHasError,
    valueChangeHandler: passwordValueChangeHandler,
    inputBlurHandler: passwordValueBlurHandler,
    reset: resetpasswordValue,
  } = useInput((value: string) => value.length >= 8, "");

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const data = {
        email: emailValue,
        password: passwordValue,
      };

      const response = loginService.lgoin(data);
      if (response.status) {
        resetemailValue();
        resetpasswordValue();
        toast.success(response.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        login("user", response.status);
        navigate("/home");
      } else throw new Error(response.message);
    } catch (error: any) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <FormLayOut>
      <form onSubmit={submitHandler} className="grid grid-cols-1 gap-1">
        <div className="mb-6">
          <CustomInput
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Username"
            name="username"
            value={emailValue}
            handleInputOnChange={emailValueChangeHandler}
            lable="username"
            handleInputOnBlur={emailValueBlurHandler}
            hasError={emailValueHasError}
          />
        </div>

        <div className="mb-6">
          <CustomInput
            type="password"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Password"
            name="password"
            value={passwordValue}
            handleInputOnChange={passwordValueChangeHandler}
            lable="password"
            handleInputOnBlur={passwordValueBlurHandler}
            hasError={passwordValueHasError}
          />
        </div>

        <button
          className="disabled:cursor-not-allowed bg-blue-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-700"
          type="submit"
          disabled={!(emailValueIsValid && passwordValueIsValid)}
        >
          Login
        </button>
      </form>
    </FormLayOut>
  );
};

export default Login;
