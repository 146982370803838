import React from "react";
import { useNavigate } from "react-router-dom";

export default function Home() {
    const navigate = useNavigate();
    const goTo = (pageName:string) => navigate(pageName)
  return (
    <div className="flex justify-start items-center ml-4">
      <div className="flex space-x-4">
        <div onClick={()=>goTo('/query')} className="cursor-pointer w-64 h-64 p-4 cardHoverEffect">
          <div className="flex items-center justify-center">
            <svg
              color="#cdab34"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="80"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="11" cy="11" r="8" />
              <line x1="21" y1="21" x2="16.65" y2="16.65" />
            </svg>
          </div>
          <div className="text-center mt-7">
            <h3 className="text-xl font-semibold">Search</h3>
            <p className="text-gray-500">Perform a search on lock Reference Code</p>
          </div>
        </div>
        <div onClick={()=>goTo('/requests')} className="cursor-pointer w-64 h-64 p-4 cardHoverEffect">
          <div className="flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              color="#cdab34"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M4 16h16M4 8h16M4 12h16"></path>
            </svg>
          </div>
          <div className="text-center mt-7">
            <h3 className="text-xl font-semibold">Requests</h3>
            <p className="text-gray-500">View requests , search and export</p>
          </div>
        </div>
      </div>
    </div>
  );
}
