import React, { useContext } from "react";
import {
  ModalContext,
  RejectPledgingRequestModalContext,
} from "../../state/state-provider";
import { QueryService } from "../../services/QueryService";
import { toast } from "react-toastify";
import ReasonsDropDown from "./ReasonsDropDown";
import { useNavigate } from "react-router-dom";

const RejectPledgingRequest: React.FC = () => {
  const navigate = useNavigate();

  const { isOpenRejectModal, toggleRejectModal, rejectRequestModalData } =
    useContext(RejectPledgingRequestModalContext);
  const queryService = new QueryService();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const response = await queryService.delete(rejectRequestModalData ?? {});
    
    if (response?.error) {
      toast.error(response?.error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    if (response.status === 200) {
      toast.success("Request Deleted Successfully", {
        position: "top-center",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        onClose: () => navigate("/home"),
      });
      toggleRejectModal(false);
      return;
    }
  };

  return (
    <>
      {isOpenRejectModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <div
            className="bg-white rounded-lg shadow-lg overflow-hidden transform transition-all max-w-lg w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white px-4 pt-1 pb-1 sm:p-6 sm:pb-4">
              <form onSubmit={handleSubmit}>
                <div className="w-full p-1 flex flex-wrap justify-center md:justify-center lg:mb-5">
                  <p className="font-bold my-2 text-sm sm:text-base">
                    Are you sure you want to reject this pledging request
                  </p>
                </div>

                <div className="w-full p-1 flex flex-wrap justify-center md:justify-start ">
                  <ReasonsDropDown />
                </div>

                <div className="flex justify-end">
                  <button
                    type="button"
                    className="bg-gray-200 text-gray-700 font-bold py-2 px-4 rounded mr-2"
                    onClick={() => toggleRejectModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="disabled:opacity-50 disabled:cursor-not-allowed bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    disabled={rejectRequestModalData?.rejectionReason == ""}
                  >
                    Reject
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RejectPledgingRequest;
