export const moneyFormat = (numberString: string) => {
    const number = parseFloat(numberString);
    return number.toLocaleString(undefined, {
      style: "currency",
      currency: "EGP",
    });
  };

export const dateFormat = (dateString:string|undefined) => {
  if (!dateString) return '';
  const date = new Date(String(dateString));
  const options:any = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}


export const convertStringToArray = (string:String) => string.split(',');