import React from "react";
import { FunctionComponent } from "react";

interface Props {
  type: string;
  placeholder?: string;
  handleInputOnChange?: void | any;
  handleInputOnBlur?: void | any;
  value: any;
  name: string;
  className?: string;
  lable?: string;
  error?: string;
  isValid?: boolean;
  hasError?: boolean;
  disabled?: boolean;
  focused?: boolean;
}

const CustomInput: FunctionComponent<Props> = (props) => {
  const {
    type,
    placeholder,
    handleInputOnChange,
    handleInputOnBlur,
    name,
    className,
    error,
    value,
    disabled,
    focused,
    hasError
  } = props;
  const lable = props.lable || props.placeholder;  

  return (
    <>
      <label
        htmlFor={lable}
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        {lable}
      </label>

      <input
        onFocus={() => focused}
        onBlur={handleInputOnBlur}
        className={` ${className} ${hasError ? "border-red-500 border-2" : ""} `}
        type={type}
        onChange={handleInputOnChange}
        value={value}
        placeholder={placeholder}
        name={name}
        disabled={disabled}
      />
      {hasError && (
        <span className="py-2 ml-1 my-2 font-semibold text-xs text-red-600"> Invalid {name} </span>
      )}
    </>
  );
};

export default React.memo(CustomInput);
